<template>
  <div class="app-list">
    <div
      class="app-item basic-shadow"
      v-for="(item, index) in appList"
      v-bind:class="{
        'item-active': selected == item.id,
        'item-invalid': item.flag != 1,
      }"
      :key="item.id"
      @click="clickAppItem(item)"
    >
      <div class="title">
        <span>{{ index + 1 }}.</span>
        {{ item.appName }}
      </div>
      <div class="tags">
        <el-tag size="mini">{{ item._caption.appType }}</el-tag
        >&nbsp;
        <el-tag type="danger" size="mini" v-if="item.authFlag == 0">{{
          item._caption.authFlag
        }}</el-tag>
        <el-tag type="success" size="mini" v-else>{{
          item._caption.authFlag
        }}</el-tag>
        &nbsp;<el-tag v-if="item.flag != 1" size="mini" type="info">{{
          item._caption.flag
        }}</el-tag>
        <el-tag v-if="item.defaultFlag == 1" size="mini" type="warning">
          默认
        </el-tag>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "wx-app-list",
  data() {
    return {
      selected: 0,
      appList: [],
    };
  },
  created() {
    this.refresh();
  },
  methods: {
    unselect() {
      this.selected = 0;
    },
    async refresh() {
      let status;
      try {
        let data = await this.$http.doApi("fw-wx-app-list", { wrapper: true });
        this.appList = data.rows;
        status = { status: "success" };
      } catch (e) {
        status = { status: "failed", e };
      } finally {
        this.$emit("loaded", status);
      }
    },
    clickAppItem(app) {
      this.selected = app.id;
    },
  },
  watch: {
    selected(val) {
      this.$emit("select", val);
    },
  },
};
</script>

<style lang="less">
.app-list {
  padding: 10px 10px;

  .app-item {
    padding: 12px;
    cursor: pointer;
    background-color: #ffffff;
    border: 1px #cccccc solid;
    border-radius: 5px;
    margin-bottom: 10px;

    .title {
      font-weight: 600;
    }

    .tags {
      padding-left: 1.1rem;
      margin-top: 5px;
    }
  }

  .item-active {
    border: 1px rgb(255, 83, 83) solid;
    transform: scale(1.02, 1.02);
    transition: 300ms;
  }

  .item-invalid {
    background-color: rgb(221, 221, 221);
  }
}
</style>